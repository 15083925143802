<template>
  <div class="mt-3 table-responsive-sm">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col" class="bb-none text-center text-info">
            <img
              src="@/assets/img/teams_icon.png"
              alt="Teams"
              width="30"
              height="30"
            />
          </th>
          <th
            scope="col"
            class="bb-none text-center"
            v-for="player in tgrid"
            :key="player.id_player_sl"
          >
            <span class="align-middle text-white font-weight-normal">{{ player.player_title }}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="player in tgrid" :key="player.id_player_sl">
          <th scope="row" class="text-center align-middle">
	          <span class="align-middle text-white font-weight-normal">{{ player.player_title }}</span>
          </th>
          <td
            class="text-center align-middle"
            v-for="player2 in tgrid"
            :key="player2.id_player_sl"
          >
            <span
              v-if="player.id_player_sl === player2.id_player_sl"
              class="align-middle text-white"
              >///</span
            >
            <div v-else class="d-block align-middle">
              <div
                v-for="stage in sgrid"
                :key="stage.id"
                class="container py-0 px-2"
              >
                <GridGamesScore1x1
                  :games="stage.games"
                  :idteam1="player.id_player_sl"
                  :idteam2="player2.id_player_sl"
                  :dataSport="dataSport"
                ></GridGamesScore1x1>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "TourGrid",
  props: ["sgrid", "tgrid", "dataSport"],
  components: {
    GridGamesScore1x1: () => import("@/components/blocks/counter_strike/GridGamesScore1x1.vue")
  }
};
</script>

<style scoped>
.table {
  border-bottom: 1px solid #dee2e6 !important;
}
.table td,
.table th {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
</style>
